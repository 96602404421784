import { Component, Input, OnInit } from '@angular/core';
import { DTO } from 'src/dto/dto';
import { UrlSanitizer } from "../../../../../utils/pipes/url-sanitizer.pipe";
import { ThemeLoaderService } from 'src/app/services/theme-loader.service';

@Component({
    selector: 'app-asset-documentation',
    templateUrl: './asset-documentation.component.html',
    styleUrls: ['./asset-documentation.component.scss'],
    standalone: true,
    imports: [
        UrlSanitizer
    ]
})
export class AssetDocumentationComponent implements OnInit {
    public docLink: string | undefined;
    public imgLink: string | undefined;

    private _asset: DTO;
    @Input()
    public set asset(asset: DTO) {
        this._asset = asset;
        this.initialize();
    }
    public get asset() {
        return this._asset;
    }

    constructor(private themeLoader: ThemeLoaderService) { }

    ngOnInit() {
        this.themeLoader.theme$.subscribe(() => this.initialize());
    }

    private initialize() {
        if (!this.asset) return;

        const docLink = DTO.getMetaData(this.asset.dto)?._docLink || "";
        this.docLink = `${docLink}?dialogMode=true&theme=${this.themeLoader.theme}`;
        this.imgLink = this.asset.icon;
    }
}
